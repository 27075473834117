
import { defineComponent, ref, reactive, computed, onUnmounted, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import Loader from '@/components/UI/Loader.vue'
import ListingTable from '@/components/UI/ListingTable.vue'
import Pagination from '@/components/UI/Pagination.vue'
import Button from '@/components/UI/Button.vue'
import Popup from '@/components/Popup.vue'
import { superAdminCompanyActionTypes } from '@/store/superAdminCompany/actions'
import { superAdminMutationTypes } from '@/store/superAdminCompany/mutations'
export default defineComponent({
  name: 'External data',
  setup() {
    const searchBy = ref('')
    const store = useStore()
    const route = useRoute()
    const selectedTabName = ref('notInLeaseData')
    const router = useRouter()
    const loader = computed(() => store.state.superAdminCompany.loader)
    const showPopup = ref(false)
    const getNotInLeaseRateData = computed(() => store.state.superAdminCompany.notInLeaseRateData)
    const totalLeaseRateData = ref(42)
    const tabs = reactive([
      { name: 'notInLeaseData', isActive: true }
    ])
    const leaseRateDataResponse = computed(() => store.state.superAdminCompany.leaseRateDataResponse) 
    const leaseRateDataLoader = computed(() => store.state.superAdminCompany.leaseRateDataLoader)
    const leaseRateDataMessage = computed(() => store.state.superAdminCompany.leaseRateDataMessage) 
    const initializeRequest = async (query) => {
      await store.dispatch(superAdminCompanyActionTypes.GET_NOTINLEASERATE, { query: query })
    }
    onMounted(async () => {
      initializeRequest(route.query)
    })
    watch(() => route.query, () => {
      initializeRequest(route.query)
    })
    const getFormatedDate = (value) => {
      return value.split('-').reverse().join('-')
    }
    const setPage = (pageNumber) => {
      const query = Object.assign({}, route.query, { page: pageNumber })
      router.push({ query })
    }
    const leaseRateDataHeadings = reactive([
      { label: 'ID', key: 'unique_identity_101'},
      { label: 'Brand', key: 'local_make_name_128' },
      { label: 'Model', key: 'local_model_name_129' },
      { label: 'Version', key: 'local_version_name_131' },
      { label: 'Consumerprice', key: 'retail_price_902' },
      { label: 'Mileage/duration missing', key: 'foundCombinations' }
    ])
    const sortBy = (value) => {
      const query = Object.assign({}, route.query, { orderBy: value['orderBy'], order: value['order'] })
      router.push({ query })
    }
    const selectedTab = (name: string) => {
      tabs.forEach((tab) => {
        tab.isActive = tab.name == name
      })
      selectedTabName.value = name
    }
    const uploadLeaseData = () => {
      console.log('uploadLeaseData')
    }
    const closePopup = () => {
      showPopup.value = false
    }
    const fileChange = async (event) => {
      const fileDetails = event.target.files
      const formData = new FormData()
      formData.append('file', fileDetails[0] as File)
      if (fileDetails[0])
        event.target.value = ''
        await store.dispatch(superAdminCompanyActionTypes.UPLOAD_LEASERATE, { body: formData })
          if (leaseRateDataResponse.value == 'success' && Array.isArray(leaseRateDataMessage.value) && leaseRateDataMessage.value.length)
            showPopup.value = true
          if (leaseRateDataResponse.value == 'success')  
            initializeRequest(route.query)
    }
    onUnmounted(() => {
      store.commit(superAdminMutationTypes.SET_LEASERATEDATA_RESPONSE, false)
      store.commit(superAdminMutationTypes.SET_LEASERATEDATA_LOADER, false)
      store.commit(superAdminMutationTypes.SET_LEASERATEDATA_MSG, false)
    })
    return {
      searchBy, loader, route, selectedTab, selectedTabName, sortBy, leaseRateDataHeadings, getNotInLeaseRateData, totalLeaseRateData, setPage, uploadLeaseData, fileChange,
      leaseRateDataLoader,
      leaseRateDataResponse,
      tabs,
      leaseRateDataMessage,
      showPopup, 
      closePopup,
      getFormatedDate
    }
  },
  components: {
    Loader,
    ListingTable,
    Pagination,
    Button,
    Popup
  }
})
